<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ data.name ? data.name : "Новое задание" }}
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :errors="errors" :config="config" @validate="validate($event)" />
          <a-input-selectuser
            class="pt-2"
            :model="oModel['watchers']"
            v-model="data['watchers']"
            :values="data"
            :config="config"
            @input="validate($event)"
            :error="errors['watchers']"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-add :icon="'far fa-calendar-alt'" :title="'Расписание'" v-if="showScheduleButton()" @click="cronDialogShow = true" />
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" v-if="getAccess('task.edit', { users: [data.createdby_id, data.user_id, user_id] }) || !id" :disabled="status.status == 'ready'" />
        <a-btn-delete
          v-if="id && getAccess('task.delete', { data: data.status == 0 ? data : {} })"
          :disabled="status.status == 'ready'"
          @click="removeDialogShow = true"
        />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <cron-dialog v-model="cronDialogShow" v-if="cronDialogShow" :task="data" @link-schedule="setSchedule" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, getAccess],
  components: {
    cronDialog: () => import("./taskCronDialog"),
  },
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    status: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: JSON.parse(JSON.stringify(this.$store.getters["config/get"].models.tasks)),
      api: "/mechti/tasks",
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить это задание?",
      cronDialogShow: false,
      user_id: null,
      defaults: {
        date_event: new Date(),
        is_important: 2,
        status: 0,
      },
      suEditShow: false,
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.form));
      let status = this.data.status || 0;
      model.forEach((el) => {
        if (![0, 100, 200].includes(status) && el?.setReadonly) {
          el.readonly = true;
          console.log("set model element readonly");
        }
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.m?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
      });
      return model;
    },
    readonly() {
      let status = this.data.status || 0;
      if (![0, 100, 200].includes(status)) {
        //this.config.readonly = true;
        return true;
      }
      return false;
    },
    config() {
      return { dense: true, readonly: this.status.status == "ready" };
    },
  },
  watch: {
    "data.status"(v) {
      if (v) {
        //  this.config.readonly = true;
        //console.log("set read only");
      }
    },
    value() {
      if (this.value) {
        this.updateData(this.id);
        this.user_id = this.data.user_id;
        if (this.id) {
        } else {
          this.getFormModel(["status"])[0].type = "";
        }
      }
    },
  },
  created() {},
  methods: {
    async setSchedule(e) {
      let data = { id: this.data.id, params: this.data.params || {} };
      data.params.schedule_id = e;
      this.loading = true;
      let response = await this.$axios.post(this.api, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (response.data.data) {
        this.$root.$emit("show-info", {
          text: "Рассписание создано успешно",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Рассписание создалось с ошибкой",
        });
      }
    },
    afterFetchData() {
      if (this.id)
        if (["construction-goods", "construction-schedule"].includes(this.data?.params?.parent)) {
          this.getFormModel(["type"])[0].hidden = true;
        }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$emit("close");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
    },
    showScheduleButton(type) {
      if (!this.id) return false;

      //  if (![6, 13].includes(this.$root.profile.id)) return false;
      if (this.data.createdby_id !== this.$root.profile.id) return false;

      return this.m.types.find((el) => {
        return el.value == this.data.type;
      })?.canSchedule;
    },
  },
};
</script>
